"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Blizzard {
    constructor(scene) {
        this.initBlizzard(scene);
        this.currentScene.blizzard = this;
    }
    /**
     * Add seven particle emitters to create the blizzard effect, for now they are not on.
     * @param scene
     */
    initBlizzard(scene) {
        this.currentScene = scene;
        this.currentScene.blizzardParticles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            x: { min: 1300, max: 1600 },
            y: -100,
            lifespan: 10000,
            speed: { min: 400, max: 600 },
            scale: { start: .4, end: .2 },
            blendMode: 'ADD',
            gravityY: 100,
            on: false,
            radial: true,
            angle: { min: 160, max: 200 },
        });
        this.currentScene.blizzardParticles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            x: { min: 1300, max: 1600 },
            y: 0,
            lifespan: 10000,
            speed: { min: 400, max: 600 },
            scale: { start: .4, end: .2 },
            blendMode: 'ADD',
            gravityY: 100,
            on: false,
            radial: true,
            angle: { min: 160, max: 200 },
        });
        this.currentScene.blizzardParticles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            x: { min: 1300, max: 1600 },
            y: 100,
            lifespan: 10000,
            speed: { min: 400, max: 600 },
            scale: { start: .4, end: .2 },
            blendMode: 'ADD',
            gravityY: 100,
            on: false,
            radial: true,
            angle: { min: 160, max: 200 },
        });
        this.currentScene.blizzardParticles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            x: { min: 1300, max: 1600 },
            y: 200,
            lifespan: 10000,
            speed: { min: 400, max: 600 },
            scale: { start: .4, end: .2 },
            blendMode: 'ADD',
            gravityY: 100,
            on: false,
            radial: true,
            angle: { min: 160, max: 200 },
        });
        this.currentScene.blizzardParticles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            x: { min: 1300, max: 1600 },
            y: 300,
            lifespan: 10000,
            speed: { min: 400, max: 600 },
            scale: { start: .4, end: .2 },
            blendMode: 'ADD',
            gravityY: 100,
            on: false,
            radial: true,
            angle: { min: 150, max: 200 },
        });
        this.currentScene.blizzardParticles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            x: { min: 1300, max: 1600 },
            y: 400,
            lifespan: 10000,
            speed: { min: 400, max: 600 },
            scale: { start: .4, end: .2 },
            blendMode: 'ADD',
            gravityY: 100,
            on: false,
            radial: true,
            angle: { min: 150, max: 200 },
        });
        this.currentScene.blizzardParticles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            x: { min: 1300, max: 1600 },
            y: 500,
            lifespan: 10000,
            speed: { min: 400, max: 600 },
            scale: { start: .4, end: .2 },
            blendMode: 'ADD',
            gravityY: 100,
            on: false,
            radial: true,
            angle: { min: 150, max: 200 },
        });
    }
    /**
     * start blizzard by turning on all the particle emitters for 5 seconds
     */
    startBlizzard() {
        this.currentScene.blizzardParticles.emitters.list.forEach(element => {
            element.start();
        });
        this.on = true;
        let countdown = this.currentScene.time.addEvent({ delay: 5000, callback: this.endBlizzard, callbackScope: this, loop: false });
        let blizzardSoundTimer = this.currentScene.time.addEvent({ delay: 1000, callback: this.playBlizzardSound, callbackScope: this, loop: false });
    }
    /**
     * end the blizzard by turning off all the blizzard particle emiiters
     */
    endBlizzard() {
        this.currentScene.blizzardParticles.emitters.list.forEach(element => {
            element.stop();
        });
        this.on = false;
    }
    playBlizzardSound() {
        let blizzardSound = this.currentScene.sound.add('blizzard');
        blizzardSound.play('', {
            volume: this.currentScene.gameVolume,
        });
    }
}
exports.Blizzard = Blizzard;
