"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RoofAnimation {
    constructor(scene) {
        //private animation: Phaser.Animations.Animation;
        this.animationKey0 = 'roofAnim0';
        this.animationKey1 = 'roofAnim1';
        this.animationKey2 = 'roofAnim2';
        this.animationKey3 = 'roofAnim3';
        this.currentScene = scene;
    }
    addRoof0Animation() {
        //Add the house animation to light up the house
        this.currentScene.anims.create({
            key: this.animationKey0,
            frames: this.currentScene.anims.generateFrameNumbers(RoofAnimation.texture0, { start: 0, end: 1 }),
            frameRate: 10,
            repeat: 0
        });
    }
    addRoof1Animation() {
        //Add the house animation to light up the house
        this.currentScene.anims.create({
            key: this.animationKey1,
            frames: this.currentScene.anims.generateFrameNumbers(RoofAnimation.texture1, { start: 0, end: 1 }),
            frameRate: 10,
            repeat: 0
        });
    }
    addRoof2Animation() {
        //Add the house animation to light up the house
        this.currentScene.anims.create({
            key: this.animationKey2,
            frames: this.currentScene.anims.generateFrameNumbers(RoofAnimation.texture2, { start: 0, end: 1 }),
            frameRate: 10,
            repeat: 0
        });
    }
    addRoof3Animation() {
        //Add the house animation to light up the house
        this.currentScene.anims.create({
            key: this.animationKey3,
            frames: this.currentScene.anims.generateFrameNumbers(RoofAnimation.texture3, { start: 0, end: 1 }),
            frameRate: 10,
            repeat: 0
        });
    }
}
RoofAnimation.texture0 = 'house0';
RoofAnimation.texture1 = 'house1';
RoofAnimation.texture2 = 'house2';
RoofAnimation.texture3 = 'house3';
exports.RoofAnimation = RoofAnimation;
class Roof extends Phaser.GameObjects.Sprite {
    constructor(scene, roofVariable) {
        super(scene, Roof.origin.x, Roof.origin.y, (Roof.texture + roofVariable));
        this.xDisplaySize = 600;
        this.yDisplaySize = 150;
        this.name = 'roof';
        this.initRoofSprite(scene);
        this.currentScene.add.existing(this);
    }
    initRoofSprite(scene) {
        this.currentScene = scene;
        this.setDisplaySize(this.xDisplaySize, this.yDisplaySize);
        this.setName(this.name);
        this.currentScene.physics.world.enable(this);
        this.body.immovable = true;
        this.currentScene.addRoofToGroup(this);
    }
}
Roof.origin = { x: 0, y: 0 };
Roof.texture = 'house';
exports.Roof = Roof;
