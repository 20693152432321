"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ObstacleRandomizer {
    constructor(scene) {
        this.randomObstacle = Math.floor(Math.random() * 2);
        this.obstacleTime = 5000;
        this.initRandomizer(scene);
    }
    initRandomizer(scene) {
        this.currentScene = scene;
        this.timedEvent = this.currentScene.time.addEvent({ delay: this.obstacleTime, callback: this.addObstacle, callbackScope: this, loop: true });
    }
    addObstacle() {
        if (this.currentScene.secondsLeft > 0) {
            //if they have more then two points add a random obstacle
            let randomObstacle = Math.floor(Math.random() * 100) + 1;
            //let randomObstacle = 20;
            //add drone
            if (randomObstacle <= 15 && this.currentScene.drone == null && this.currentScene.score > 4) {
                this.currentScene.addDrone();
            }
            else if (randomObstacle > 15 && randomObstacle <= 30 && !this.currentScene.blizzard.on) {
                this.currentScene.blizzard.startBlizzard();
            }
            //add blizzard
            else if (randomObstacle > 30 && randomObstacle <= 45 && this.currentScene.fog == null) {
                this.currentScene.addFog();
            }
            //add birds
            else if (randomObstacle > 45 && randomObstacle <= 65) {
                this.currentScene.addTwoTurtleDoves(true, true);
            }
            //add wind
            else if (randomObstacle > 65 && randomObstacle <= 85) {
                if (this.currentScene.drone == null) {
                    this.currentScene.addWind();
                }
            }
        }
    }
}
exports.ObstacleRandomizer = ObstacleRandomizer;
