"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class startButton extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, startButton.origin.x, startButton.origin.y, startButton.texture);
        this.initStartButtonSprite(scene);
        this.scene.add.existing(this);
    }
    initStartButtonSprite(scene) {
        this.currentScene = scene;
        this.depth = 2;
        this.setInteractive();
        //TODO possibly move this out to it's own class.
        this.currentScene.anims.create({
            key: 'startButtonAnim',
            frames: this.currentScene.anims.generateFrameNumbers('startButton', { start: 0, end: 1 }),
            frameRate: 12,
            yoyo: true,
        });
        this.on('pointerdown', function (event) {
            this.anims.play('startButtonAnim');
        });
        this.on('animationcomplete', this.startButtonAnimationComplete, this);
    }
    startButtonAnimationComplete() {
        this.currentScene.registry.get('socket').emit('startGame');
        this.currentScene.scene.start('MainScene');
    }
}
startButton.origin = { x: 300, y: 460 };
startButton.texture = 'startButton';
exports.startButton = startButton;
