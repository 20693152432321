"use strict";
/**
 * @author       Daniel Knoben, Scott Dunham, Leah Ruisenor, Digitsensitive
 * @copyright    2018 Digitsensitive
 * @license      Digitsensitive
 */
Object.defineProperty(exports, "__esModule", { value: true });
const axios_1 = require("axios");
const vex = require("vex-js");
class GameOverScene extends Phaser.Scene {
    constructor() {
        vex.registerPlugin(require('vex-dialog'));
        vex.defaultOptions.className = 'vex-theme-plain';
        super({
            key: "GameOverScene"
        });
    }
    preload() {
        //Load Image Assets
        this.load.image('gameOver', './assets/game/gameover.jpg');
        // Load high score form
        this.load.html('highScoreForm', './assets/game/highScoreForm.html');
        //Load Sprites
        this.load.spritesheet('startOver', './assets/game/restart.png', { frameWidth: 96, frameHeight: 96 });
        this.load.spritesheet('mainMenu', './assets/game/mainmenu.png', { frameWidth: 96, frameHeight: 96 });
        this.load.spritesheet('submitScore', './assets/game/submit.png', { frameWidth: 228.5, frameHeight: 64 });
        this.load.spritesheet('thankyouBtn', './assets/game/disabled-submit-btn.png', { frameWidth: 228.5, frameHeight: 64 });
        //Load Audio
        this.load.audio('game-over', [
            'assets/audio/timer-ding.mp3'
        ]);
    }
    create() {
        //Add game over images
        this.add.image(550, 300, 'gameOver');
        //Play the game over sound
        this.gameOverSound = this.sound.add('game-over');
        this.gameOverSound.play('', {
            volume: .5
        });
        //Add Score Text
        let score = this.scene.get("MainScene").data.get('score');
        this.scoreText = this.add.text(270, 200, String(score), { fontFamily: 'Script', fontSize: '60pt', boundsAlignH: "center", boundsAlignV: "middle", fill: '#0a5c71' });
        this.scoreText.setPadding(0, 5, 10, 0);
        this.scoreText.setOrigin(0.5);
        this.scoreText.depth = 1;
        //Submit Score Button
        this.submitScore = this.add.sprite(275, 330, 'submitScore').setInteractive();
        this.anims.create({
            key: 'submitScoreAnim',
            frames: this.anims.generateFrameNumbers('submitScore', { start: 0, end: 1 }),
            frameRate: 12,
            yoyo: true,
        });
        this.submitScore.on('pointerdown', function (event) {
            this.createHighScoreForm(score);
            this.submitScore.play('submitScoreAnim');
        }, this);
        // Start Over Button (to main menu)
        this.anims.create({
            key: 'mainMenuAnim',
            frames: this.anims.generateFrameNumbers('mainMenu', { start: 0, end: 1 }),
            frameRate: 12,
            yoyo: true,
        });
        let menu = this.add.sprite(335, 450, 'mainMenu').setInteractive();
        menu.on('pointerdown', function (event) {
            menu.play('mainMenuAnim');
            this.scene.start("StartScene");
        }, this);
        // Replay Game Button
        this.anims.create({
            key: 'startOverAnim',
            frames: this.anims.generateFrameNumbers('startOver', { start: 0, end: 1 }),
            frameRate: 12,
            yoyo: true,
        });
        let replay = this.add.sprite(210, 450, 'startOver').setInteractive();
        replay.on('pointerdown', function (event) {
            replay.play('startOverAnim');
            this.registry.get('socket').emit('startGame');
            this.scene.start("MainScene");
        }, this);
        //Add High Scores and Initials Text to leaderboard
        this.createLeaderboard();
    }
    update() {
    }
    // Remove High Scores and Initials Text from leaderboard
    removeLeaderboard() {
        // get all the children in the initial group
        let arrTextInitials = this.initialsGroup.getChildren();
        arrTextInitials.forEach((text) => {
            text.setVisible(false);
        });
        // get all the children in the highscore group
        let arrTextScore = this.highScoreGroup.getChildren();
        arrTextScore.forEach((text) => {
            text.setVisible(false);
        });
    }
    createHighScoreForm() {
        // Open modal
        // TODO: Only allow high score to be submitted once
        let self = this;
        let formTemplate = this.cache.html.get('highScoreForm');
        vex.open({
            showCloseButton: false,
            appendLocation: '#game-container',
            overlayClosesOnClick: false,
            unsafeContent: formTemplate,
            afterOpen: function () {
                // Listen for submit events
                let form = document.getElementById('highScoreForm');
                form.addEventListener('submit', self.submitHighScore.bind(self));
                // Listen for click events on our custom close button
                document.querySelector('.closeHighScore').addEventListener('click', function (e) {
                    e.preventDefault();
                    vex.closeAll();
                }, false);
            }
        });
        // TODO: DOM element functionality isn't in Phaser 3 yet.
        //var highScoreForm = this.add.dom(400, 600, 'highScoreForm');
        /*highScoreForm.on('click', function (event) {
            console.log('Current score:', score);
            if (event.target.name === 'submitHighScore'){
                console.log('CLICKED SUBMIT HIGH SCORE BUTTON');
            }
        });*/
    }
    // Save a score to the high score database
    submitHighScore(event) {
        let self = this;
        event.preventDefault();
        let score = this.scene.get("MainScene").data.get('score');
        let email = document.getElementById('highScoreEmail').value;
        let initials = document.getElementById('highScoreInitials').value;
        axios_1.default.post('/submitScore', {
            score: score,
            email: email ? email : null,
            initials: initials ? initials : ''
        }).then(function (res) {
            // Remove leaderboard text
            self.removeLeaderboard();
            // Update leaderboard to account for new score
            self.createLeaderboard();
            // Remove submit score button
            self.submitScore.destroy();
            // Update submit button in game scene to thank you
            let thankyouBtn = self.add.sprite(275, 330, 'thankyouBtn');
            vex.closeAll();
        }).catch(function (err) {
            let errorContain = document.querySelector('#highScoreForm .error');
            if (errorContain) {
                let errorText = err.response.data.errors
                    ? err.response.data.errors.join('<br /><br />')
                    : 'An error occurred';
                errorContain.innerHTML = errorText;
            }
        });
    }
    //Add High Scores and Initials Text to Leaderboard
    createLeaderboard() {
        let self = this;
        let xCordInitials = 520;
        let yCordInitials = 116;
        let xCordScore = 620;
        let yCordScore = 115;
        let xCordLine = 500;
        let yCordLine = 155;
        let timestamp = new Date().getTime();
        axios_1.default.get('/highScores?t=' + timestamp).then(function (data) {
            // add groups to scene
            self.initialsGroup = self.add.group();
            self.highScoreGroup = self.add.group();
            // data from database
            var highScoreArr = data['data']['scores'];
            for (var i = 0; i < highScoreArr.length; i++) {
                // initials
                let initialsText = self.add.text(xCordInitials, yCordInitials, String(highScoreArr[i]['initials']), { fontFamily: 'Sans', fontSize: '20pt', fill: '#b3dde3' });
                self.initialsGroup.add(initialsText);
                // score
                let highScoreText = self.add.text(xCordScore, yCordScore, String(highScoreArr[i]['score']), { fontFamily: 'Sans', fontSize: '20pt', fill: 'white' });
                self.highScoreGroup.add(highScoreText);
                var line = new Phaser.Geom.Rectangle(xCordLine, yCordLine, 180, 2);
                var graphics = self.add.graphics({ fillStyle: { color: 1863819 } });
                graphics.fillRectShape(line);
                // two columns 5 rows
                if (i < 4) {
                    yCordInitials += 48;
                    yCordScore += 48;
                    yCordLine += 48;
                }
                else if (i == 4) {
                    xCordInitials = 750;
                    yCordInitials = 116;
                    xCordScore = 850;
                    yCordScore = 115;
                    xCordLine = 730;
                    yCordLine = 155;
                }
                else {
                    yCordInitials += 48;
                    yCordScore += 48;
                    yCordLine += 48;
                }
            }
        }).catch(function (err) {
            console.error(err);
        });
    } //end createLeaderboard
}
exports.GameOverScene = GameOverScene;
