"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PresentBar extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, PresentBar.origin.x, PresentBar.origin.y, PresentBar.texture);
        this.initPresentBarSprite(scene);
        this.scene.add.existing(this);
    }
    initPresentBarSprite(scene) {
        this.currentScene = scene;
        this.depth = 2;
        this.currentScene.anims.create({
            key: 'presentBarAnim',
            frames: this.currentScene.anims.generateFrameNumbers('presentBar', { start: 0, end: 9 }),
            frameRate: 17,
            repeat: -1
        });
    }
}
PresentBar.origin = { x: 680, y: 37 };
PresentBar.texture = 'presentBar';
exports.PresentBar = PresentBar;
