"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const chimney_1 = require("./chimney");
class Present extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y) {
        let xPoint = x - 40;
        let yPoint = y + 70;
        super(scene, xPoint, yPoint, Present.texture);
        this.initPresentSprite(scene);
        this.scene.add.existing(this);
    }
    initPresentSprite(scene) {
        this.currentScene = scene;
        this.currentScene.addPresentToGroup(this);
        this.currentScene.physics.world.enable(this);
        this.body.setSize(26, 22);
        this.depth = 1;
        this.body.setBounce(0, 0);
        this.body.setGravityY(350);
        this.body.setVelocity(200, 5);
        this.body.setAngularVelocity(200);
        this.on('animationcomplete', this.presentAnimComplete, this);
        this.on('destroy', function () {
            this.canThrow = true;
        }, this);
        //set up the world collide bounds on this object so it will "explode" when it hits the edge of the world
        this.body.setCollideWorldBounds(true);
        this.body.onWorldBounds = true;
        //add a collider action to detect if the present hits the chimney
        this.currentScene.physics.add.collider(this, this.currentScene.chimneyHitZones, chimney_1.Chimney.hitChimney, null, null);
        this.getThrowIfDestroyed = true;
    }
    presentAnimComplete(animation, frame) {
        // Destroy any presents (there should only ever be one, but this might changed based on future powerups)
        this.destroy();
        if (this.getThrowIfDestroyed) {
            this.currentScene.canThrow++;
        }
        //  Animation is over, let's fade the sprite out
        this.currentScene.tweens.add({
            targets: this,
            duration: 250,
            alpha: 0
        });
    }
}
Present.origin = { x: 165, y: 160 };
Present.texture = 'present';
exports.Present = Present;
class PresentAnimation {
    constructor(scene) {
        //private animation: Phaser.Animations.Animation;
        this.animationKey = 'presentAnim';
        this.currentScene = scene;
    }
    addPresentAnimation() {
        //when present is destroyed plays animation
        this.currentScene.anims.create({
            key: this.animationKey,
            frames: this.currentScene.anims.generateFrameNumbers(PresentAnimation.texture, { start: 0, end: 7 }),
            frameRate: 24,
            repeat: 0
        });
    }
}
PresentAnimation.texture = 'present';
exports.PresentAnimation = PresentAnimation;
