"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const presentFragments_1 = require("./presentFragments");
class Drone extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, Drone.origin.x, Drone.origin.y, Drone.texture);
        this.initDroneSprite(scene);
        this.currentScene.drone = this;
        this.scene.add.existing(this);
    }
    initDroneSprite(scene) {
        this.currentScene = scene;
        this.hit = false;
        this.followPath = false;
        this.currentScene.physics.world.enable(this);
        this.depth = 1;
        //add sound
        this.droneSound = this.currentScene.sound.add('drone-flying');
        this.droneSound.play('', {
            volume: this.currentScene.gameVolume - .9,
            loop: true
        });
        this.droneHoverPointX = Math.floor(Math.random() * 75) + 270;
        this.droneHoverPointY = Math.floor(Math.random() * 75) + 270;
        this.currentScene.tweens.add({
            targets: this,
            x: this.droneHoverPointX,
            y: this.droneHoverPointY,
            duration: 1000,
            ease: 'Quad.easeOut',
            yoyo: false,
            onComplete: this.circleDrone
        });
        this.setData('vector', new Phaser.Math.Vector2());
        this.play('droneAnim');
        //this.on('animationcomplete', this.dovesAnimationComplete, this);
        //add a callback that detects if a present collides with the doves
    }
    circleDrone(tween, target) {
        let drone = target[0];
        drone.body.setCollideWorldBounds(true);
        drone.body.onWorldBounds = true;
        drone.t = 0;
        drone.followPath = true;
        drone.currentScene.path = new Phaser.Curves.Path(drone.droneHoverPointX, drone.droneHoverPointY);
        drone.currentScene.path.circleTo(-50);
        drone.currentScene.path.moveTo(drone.droneHoverPointX, drone.droneHoverPointY);
        //  Rotate this circle so it completes the loop
        drone.currentScene.path.circleTo(-50, true, 180);
        drone.currentScene.tweens.add({
            targets: drone,
            t: 1,
            ease: 'Linear',
            duration: 4000,
            repeat: -1
        });
        drone.currentScene.physics.add.collider(drone, drone.currentScene.presents, Drone.hitDrone, null, null);
    }
    /**
     * If a present hits the dove
     */
    static hitDrone(drone, present) {
        //kill the tween
        drone.currentScene.tweens.killTweensOf(drone);
        drone.droneSound.stop();
        drone.dronePresentCollison = drone.currentScene.sound.add('drone-hits-present');
        drone.dronePresentCollison.play('', {
            volume: drone.currentScene.gameVolume - .9,
        });
        //kill the scene drone and path
        delete drone.currentScene.path;
        if (present.getThrowIfDestroyed) {
            drone.currentScene.canThrow++;
        }
        drone.currentScene.fireButton.anims.playReverse('fireButtonResetAnim', true);
        //stop following the path, and have the drone spin and fall
        drone.followPath = false;
        drone.body.setAngularVelocity(300);
        drone.body.setGravityY(350);
        let presentParticles = new presentFragments_1.PresentFragments(drone.currentScene, present.x, present.y);
        presentParticles.emitParticle(50, present.x, present.y + 20);
        present.destroy();
    }
}
Drone.origin = { x: 300, y: 650 };
Drone.texture = 'drone';
exports.Drone = Drone;
class DroneAnimation {
    constructor(scene) {
        //private animation: Phaser.Animations.Animation;
        this.animationKey = 'droneAnim';
        this.currentScene = scene;
    }
    addDroneAnimation() {
        //Add the house animation to light up the house
        this.currentScene.anims.create({
            key: this.animationKey,
            frames: this.currentScene.anims.generateFrameNumbers(DroneAnimation.texture, { start: 0, end: 5 }),
            frameRate: 24,
            repeat: -1
        });
    }
}
DroneAnimation.texture = 'drone';
exports.DroneAnimation = DroneAnimation;
