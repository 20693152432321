"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Destroyer extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, Destroyer.origin.x, Destroyer.origin.y, Destroyer.texture);
        this.initDestroyerSprite(scene);
        this.scene.add.existing(this);
    }
    initDestroyerSprite(scene) {
        this.currentScene = scene;
        this.currentScene.physics.world.enable(this);
        //this.setDisplaySize(250,600); // test on screen
        this.setDisplaySize(1, 600);
        //add a callback that detects if a house collides with the zone of destruction
        this.currentScene.physics.add.overlap(this.currentScene.houses, this, Destroyer.destroyHouse, null, null);
    }
    /**
    * If a house collides with the zone of destruction off screen destroy it
    */
    static destroyHouse(houses) {
        //console.log("house destroyed");
        houses.destroy();
    }
}
//static origin = {x: 20, y: 300}; // test on screen 
Destroyer.origin = { x: -3500, y: 300 };
Destroyer.texture = 'destroyerOfAllThings';
exports.Destroyer = Destroyer;
class ChimneyOverlap extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, ChimneyOverlap.origin.x, ChimneyOverlap.origin.y, ChimneyOverlap.texture);
        this.initChimneyOverlapSprite(scene);
        this.scene.add.existing(this);
    }
    initChimneyOverlapSprite(scene) {
        this.currentScene = scene;
        this.currentScene.physics.world.enable(this);
        this.setDisplaySize(1, 600);
        //add a callback that detects if a chimney collides with the zone of destruction
        this.currentScene.physics.add.overlap(this.currentScene.chimneyHitZones, this, ChimneyOverlap.detectIfHit, null, null);
    }
    /**
    * If a chimeny collides with the zone of destruction off screen note that they user missed a chimney and reset the progress bar to zero
    */
    static detectIfHit(hitZone) {
        //if the chimney hit zone has not been scored and the chimney hit zone has never hit the destruction zone before
        if (!hitZone.scored && !hitZone.hitEdgeOrWorld) {
            //not that the hit zone has now hit the ChimneyOverlap so we don't repeat this function over and over
            hitZone.hitEdgeOrWorld = true;
            //reset the present bar frame back to zero
            hitZone.currentScene.presentBar.setFrame(0);
            //resert the present bar progress to zero
            hitZone.currentScene.presentBarProgress = 0;
            //get all the presents on the screen
            let arrPresents = hitZone.currentScene.presents.getChildren();
            // Note: The Group class getChildren function return type is an array of GameObjects
            // As a result, we need to specify that we're dealing with our House class in this case.
            arrPresents.forEach((present) => {
                //update each present so that when it is destroyed they are not automatically allowed to throw another.
                present.getThrowIfDestroyed = false;
            });
            //reset the amount of presnts a user can throw at once to one
            hitZone.currentScene.canThrow = 1;
            //reset the present bar score back to 1
            hitZone.currentScene.presentBarScore = 1;
        }
    }
}
ChimneyOverlap.origin = { x: -50, y: 300 };
ChimneyOverlap.texture = 'destroyerOfAllThings';
exports.ChimneyOverlap = ChimneyOverlap;
