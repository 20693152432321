"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sled_1 = require("./sled");
const present_1 = require("./present");
const cannonStars_1 = require("./cannonStars");
class SledContainer extends Phaser.GameObjects.Container {
    constructor(scene) {
        super(scene, SledContainer.origin.x, SledContainer.origin.y);
        this.initSledContainer(scene);
        this.scene.add.existing(this);
    }
    initSledContainer(scene) {
        this.currentScene = scene;
        this.scene.physics.world.enable(this);
        //Add a new Sled to the container
        let sled = new sled_1.Sled(this.currentScene);
        this.add(sled);
        this.setInteractive(new Phaser.Geom.Circle(0, 0, 60), Phaser.Geom.Circle.Contains);
        this.on('clicked', this.firePresent, this);
        this.currentScene.tweens.add({
            targets: this,
            x: 240,
            y: 120,
            duration: 1500,
            ease: 'Sine.easeInOut',
            yoyo: true,
            repeat: -1
        });
        this.cannonStars = new cannonStars_1.CannonStars(this.currentScene, this.x, this.y);
    }
    firePresent() {
        //If the user is allowed to throw a present
        if (this.currentScene.canThrow > 0) {
            //Add Sound Effect for Cannon
            let giftShotSound = this.currentScene.sound.add('gift-shot');
            giftShotSound.play('', {
                volume: this.currentScene.gameVolume,
            });
            //create a new present and set some options
            let present = new present_1.Present(this.currentScene, this.x, this.y);
            this.cannonStars.emitParticle(15, this.x - 40, this.y + 70);
            //do not let the user throw another present until this present hits the chimney or the edge of the world
            this.currentScene.canThrow--;
        }
    }
}
SledContainer.origin = { x: 200, y: 100 };
exports.SledContainer = SledContainer;
