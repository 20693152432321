"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PresentFragments extends Phaser.GameObjects.Particles.ParticleEmitterManager {
    constructor(scene, x, y) {
        super(scene, PresentFragments.texture);
        this.initPresentFragments(scene, x, y);
        this.scene.add.existing(this);
    }
    initPresentFragments(scene, x, y) {
        this.currentScene = scene;
        let xPoint = x;
        let yPoint = y;
        //Set the snow depth to be at the front of the game
        this.setDepth(2);
        let particleConfig = {
            frames: [0],
            x: xPoint,
            y: yPoint,
            //last for one seconds
            lifespan: 2000,
            //how fast will they move
            speed: { min: 150, max: 300 },
            //how big are they when the begin, and when they end (will make the snowflakes smaller)
            scale: { start: .3, end: .3 },
            //not on by default
            on: false,
            //only emit from specified angle
            radial: true,
            angle: { min: 45, max: 135 }
        };
        //Add two particle emitters to the game
        let particleEmitter = new Phaser.GameObjects.Particles.ParticleEmitter(this, particleConfig);
        this.addEmitter(particleEmitter);
    }
}
PresentFragments.origin = { x: 0, y: 0 };
PresentFragments.texture = 'presentFragments';
exports.PresentFragments = PresentFragments;
