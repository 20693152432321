"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Wind extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, Wind.origin.x, Wind.origin.y, Wind.texture);
        this.initWindSprite(scene);
        this.scene.add.existing(this);
    }
    initWindSprite(scene) {
        this.currentScene = scene;
        this.scene.physics.world.enable(this);
        //move it across the screen
        this.body.setVelocity(-400, 0);
        this.setFlipX(true);
        this.setFlipY(true);
        this.play('windAnim');
        //add audio
        let wind = this.currentScene.sound.add('wind-blows');
        wind.play('', {
            volume: this.currentScene.gameVolume,
        });
        //add a callback that detects if a present collides with the wind
        this.currentScene.physics.add.overlap(this, this.currentScene.presents, this.hitWind, null, this);
        //add a callback that detects if wind collides with the zone of destruction
        this.currentScene.physics.add.overlap(this, this.currentScene.destroyerOfAllThings, Wind.destroyWind, null, null);
    }
    /**
     * If a present hits the wind adjust the present trajectory
     */
    hitWind(wind, present) {
        //spin the present         
        present.body.setAngularVelocity(-300);
        //adjust the velocity (use 300 as the Y velocity value, that is the present gravity value so it does not seem to "stop" falling)
        present.body.setVelocity(0, 300);
        //note if the present hit the wind
        present.hitWind = true;
    }
    /**
     * If a wind collides with the zone of destruction off screen destroy it
     */
    static destroyWind(wind) {
        //console.log("wind destroyed");
        wind.destroy();
    }
}
Wind.origin = { x: 1200, y: 300 };
Wind.texture = 'wind';
exports.Wind = Wind;
class WindAnimation {
    constructor(scene) {
        //private animation: Phaser.Animations.Animation;
        this.animationKey = 'windAnim';
        this.currentScene = scene;
    }
    addWindAnimation() {
        //Add the house animation to light up the house
        this.currentScene.anims.create({
            key: this.animationKey,
            frames: this.currentScene.anims.generateFrameNumbers(WindAnimation.texture, { start: 0, end: 39 }),
            frameRate: 24,
            repeat: -1
        });
    }
}
WindAnimation.texture = 'wind';
exports.WindAnimation = WindAnimation;
