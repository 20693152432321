"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const roof_1 = require("./roof");
const chimney_1 = require("./chimney");
const chimneyHitZone_1 = require("./chimneyHitZone");
class House extends Phaser.GameObjects.Container {
    constructor(scene, velocity) {
        super(scene, House.origin.x, House.origin.y);
        this.initHouseContainer(scene, velocity);
        this.scene.add.existing(this);
    }
    initHouseContainer(scene, velocity) {
        this.currentScene = scene;
        this.scene.physics.world.enable(this);
        //Add a new Roof to the container
        let randomRoofTexture = Math.floor(Math.random() * 4);
        //let randomRoofTexture = 2;
        let roof = new roof_1.Roof(this.currentScene, randomRoofTexture);
        //Add a new chinmey to the container
        let chimney = new chimney_1.Chimney(this.currentScene, randomRoofTexture);
        let chimneyZone = new chimneyHitZone_1.ChimneyHitZone(this.currentScene, chimney.x, chimney.y - (chimney.displayHeight / 2), chimney.displayWidth);
        //Add a new roof shape
        //let r1 = this.add.triangle(200, 200, 0, 148, 148, 148, 74, 0, 0x6666ff);
        //add the roof and chimney to the house
        this.add(chimney);
        this.add(roof);
        this.add(chimneyZone);
        //houseContainer.add(r1);
        //set the container velocity
        this.body.setVelocityX(velocity);
        //add our house container to our group of house containers
        this.currentScene.addHouseToGroup(this);
    }
}
House.origin = { x: 1500, y: 525 };
exports.House = House;
