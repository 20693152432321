"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const house_1 = require("../objects/house");
const chimney_1 = require("../objects/chimney");
const roof_1 = require("../objects/roof");
const present_1 = require("../objects/present");
const wind_1 = require("../objects/wind");
const doves_1 = require("../objects/doves");
const sledContainer_1 = require("../objects/sledContainer");
const fireButton_1 = require("../objects/fireButton");
const pause_1 = require("../objects/pause");
const muteButton_1 = require("../objects/muteButton");
const destroyer_1 = require("../objects/destroyer");
const obstacleRandomizer_1 = require("../objects/obstacleRandomizer");
const drone_1 = require("../objects/drone");
const chimneyHitZone_1 = require("../objects/chimneyHitZone");
const fog_1 = require("../objects/fog");
const blizzard_1 = require("../objects/blizzard");
const WebFont = require("webfontloader");
const presentBar_1 = require("../objects/presentBar");
/**
 * @author       Daniel Knoben, Scott Dunham, Digitsensitive
 * @copyright    2018 Digitsensitive
 * @license      Digitsensitive
 */
class MainScene extends Phaser.Scene {
    constructor() {
        super({
            key: "MainScene"
        });
        this.canThrow = 1;
        this.presentBarProgress = 0;
        this.houseVelocity = -200;
        this.playingPresentCollisonSound = false;
        this.chimneySizeRange = 10;
        this.chimneySizeMinWidth = 90;
        this.score = 0;
        this.presentBarScore = 1;
        this.isUp = true;
        this.isloaded = false;
    }
    preload() {
        let self = this;
        // Preload custom fonts, then set up a progress bar while the rest of our assets load
        if (!this.isloaded) {
            WebFont.load({
                custom: {
                    families: ['Sans', 'Script'],
                    urls: ['build/game.css']
                },
                active: function () {
                    // Start the progress bar
                    self.progressBar();
                }
            });
        }
        // TODO: We should consider renaming our assets and loading them via a json file instead of one by one
        // Load image assets
        this.load.image('trees', './assets/game/trees.png');
        this.load.image('mountains', './assets/game/mountains.png');
        this.load.image('mountains2', './assets/game/mountains3.png');
        this.load.image('sky', './assets/game/stars3.jpg');
        this.load.image('chimney0', './assets/game/chimney.png');
        this.load.image('chimney1', './assets/game/stovePipeChimney.png');
        this.load.image('timerBackground', './assets/game/timer.png');
        this.load.image('fog', './assets/game/blizzard.png');
        // Load the destroyer
        this.load.image('destroyerOfAllThings', './assets/game/zoneOfDestruction.png');
        // Load sprite assets
        this.load.spritesheet('pauseButton', './assets/game/pause.png', { frameWidth: 45, frameHeight: 46 });
        this.load.spritesheet('muteButton', './assets/game/sound.png', { frameWidth: 45, frameHeight: 47 });
        this.load.spritesheet('fireButton', './assets/game/fire.png', { frameWidth: 114, frameHeight: 114 });
        this.load.spritesheet('wind', './assets/game/wind.png', { frameWidth: 310, frameHeight: 155 });
        this.load.spritesheet('drone', './assets/game/drone.png', { frameWidth: 65, frameHeight: 40 });
        this.load.spritesheet('house0', './assets/game/roof.png', { frameWidth: 410, frameHeight: 115 });
        this.load.spritesheet('house1', './assets/game/roof2.png', { frameWidth: 298, frameHeight: 115 });
        this.load.spritesheet('house2', './assets/game/house-brick.png', { frameWidth: 410, frameHeight: 115 });
        this.load.spritesheet('house3', './assets/game/house2-brick.png', { frameWidth: 298, frameHeight: 115 });
        this.load.spritesheet('present', './assets/game/present.png', { frameWidth: 100, frameHeight: 100 });
        this.load.spritesheet('sled', './assets/game/new-santa-sled2.png', { frameWidth: 300, frameHeight: 243 });
        this.load.spritesheet('snowflakes', './assets/game/sprites/snowflakes_large.png', { frameWidth: 64, frameHeight: 64 });
        this.load.spritesheet('blizzardSnowflakes', './assets/game/sprites/snowflakes_blizard.png', { frameWidth: 384, frameHeight: 300 });
        this.load.spritesheet('cannonStars', './assets/game/starlet.png', { frameWidth: 9, frameHeight: 9 });
        this.load.spritesheet('presentFragments', './assets/game/present-part.png', { frameWidth: 23, frameHeight: 22 });
        this.load.spritesheet('doves', './assets/game/bird.png', { frameWidth: 150, frameHeight: 150 });
        this.load.spritesheet('presentBar', './assets/game/levelup2.png', { frameWidth: 190, frameHeight: 60 });
        //Load Audio
        this.load.audio('music', [
            'assets/audio/music-loop.mp3'
        ]);
        this.load.audio('gift-shot', [
            'assets/audio/gift-shot.mp3'
        ]);
        this.load.audio('wind-blows', [
            'assets/audio/wind-blows-v2.mp3'
        ]);
        this.load.audio('gift-hits-ground', [
            'assets/audio/gift-hits-ground.mp3'
        ]);
        this.load.audio('gift-hits-chimney', [
            'assets/audio/gift-in-chimney.mp3'
        ]);
        this.load.audio('double-shot-earned', [
            'assets/audio/double-shot-earned.mp3'
        ]);
        this.load.audio('turtle-dove-flight', [
            'assets/audio/turtle-dove-flight.mp3'
        ]);
        this.load.audio('blizzard', [
            'assets/audio/snow-pelting-things.mp3'
        ]);
        this.load.audio('fog', [
            'assets/audio/fog-passes-through.mp3'
        ]);
        this.load.audio('turtle-dove-struck', [
            'assets/audio/turtle-dove-struck.mp3'
        ]);
        this.load.audio('drone-flying', [
            'assets/audio/drone-flying.mp3'
        ]);
        this.load.audio('drone-hits-present', [
            'assets/audio/drone-hit-by-gift.mp3'
        ]);
        this.load.audio('drone-hits-ground', [
            'assets/audio/drone-hits-ground.mp3'
        ]);
        this.load.audio('three-seconds-left', [
            'assets/audio/timer-three-seconds.mp3'
        ]);
        this.load.audio('two-seconds-left', [
            'assets/audio/timer-two-seconds.mp3'
        ]);
        this.load.audio('one-second-left', [
            'assets/audio/timer-one-second.mp3'
        ]);
    }
    progressBar() {
        // Figure out the positioning of the progress bar
        let canvasWidth = this.registry.get('canvasWidth');
        let progressX = (canvasWidth / 2) - 150;
        let canvasHeight = this.registry.get('canvasHeight');
        let progressY = (canvasHeight / 2) - 25;
        // Add a progress bar while we're loading assets
        let progress = this.add.graphics();
        let progressContain = this.add.graphics();
        progressContain.fillStyle(0x0A7F9C, 0.8);
        progressContain.fillRect(progressX, progressY, 320, 50);
        // Add loading text
        let width = this.cameras.main.width;
        let height = this.cameras.main.height;
        let loadingText = this.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'Loading...',
            style: {
                fontFamily: 'Script',
                fontSize: '20px',
                fill: '#B3DDE3'
            }
        });
        loadingText.setOrigin(0.5, 0.5);
        // Update the progress bar as assets are loaded
        this.load.on('progress', function (value) {
            progress.clear();
            progress.fillStyle(0xB3DDE3, 1);
            progress.fillRect(progressX + 10, progressY + 10, 300 * value, 30);
        });
        // Remove the progress bar once all assets are loaded
        this.load.on('complete', function () {
            progress.destroy();
            progressContain.destroy();
            loadingText.destroy();
            this.isloaded = true;
        }, this);
    }
    create() {
        /***********************/
        //Set up Sound Options
        /***********************/
        this.gameVolume = 1;
        this.backgroundMusicVolume = .1;
        //Add Background Music
        this.backgroundMusic = this.sound.add('music');
        this.backgroundMusic.play('', {
            volume: this.backgroundMusicVolume,
            loop: true,
        });
        //Set up sound effects
        this.destroyPresentSound = this.sound.add('gift-hits-ground');
        this.destroyDroneSound = this.sound.add('drone-hits-ground');
        this.hitBirdSound = this.sound.add('turtle-dove-struck');
        this.threeSecondsLeftSound = this.sound.add('three-seconds-left');
        this.twoSecondsLeftSound = this.sound.add('two-seconds-left');
        this.oneSecondLeftSound = this.sound.add('one-second-left');
        /***********************/
        // Create Game Groups 
        /***********************/
        //Create groups for roofs, chimneys and houses.  (Don't change this comment Scott, it is fine the way it is)
        this.roofs = this.physics.add.group({ classType: roof_1.Roof });
        this.chimneys = this.physics.add.group({ classType: chimney_1.Chimney });
        this.presents = this.physics.add.group({ classType: present_1.Present });
        this.houses = this.add.group({ classType: house_1.House });
        this.chimneyHitZones = this.add.group({ classType: chimneyHitZone_1.ChimneyHitZone });
        /***********************/
        //Add Game Animations
        /***********************/
        //Roof Animations
        let roofAnimaitons = new roof_1.RoofAnimation(this);
        roofAnimaitons.addRoof0Animation();
        roofAnimaitons.addRoof1Animation();
        roofAnimaitons.addRoof2Animation();
        roofAnimaitons.addRoof3Animation();
        //Present Animation
        new present_1.PresentAnimation(this).addPresentAnimation();
        //Wind Animation
        new wind_1.WindAnimation(this).addWindAnimation();
        //Dove Animations
        let doveAnimation = new doves_1.DoveAnimation(this);
        doveAnimation.addDovesAnimation();
        doveAnimation.addDoveCollisonAnimation();
        new drone_1.DroneAnimation(this).addDroneAnimation();
        /***********************/
        //Add Game Options
        /***********************/
        //Set the world bounds
        this.physics.world.setBoundsCollision(true, true, true, true);
        this.physics.world.on("worldbounds", this.collideWithWorldEdge, this);
        //Setup Game Options
        this.chimneyDimensions = new chimney_1.chimneyDimensions();
        /***********************/
        //Add Game Objects
        /***********************/
        //Add the background images as tile sprites
        // parameters for tileSprite(horizontal-X position, vertical-Y position, width, height, the image)
        this.sky = this.add.tileSprite(400, 250, 1400, 500, 'sky');
        this.farBackground = this.add.tileSprite(400, 350, 1400, 500, 'mountains2');
        this.background = this.add.tileSprite(550, 415, 1200, 450, 'mountains');
        this.trees = this.add.tileSprite(550, 535, 1200, 150, 'trees');
        this.timerBackground = this.add.sprite(1011, 58, 'timerBackground');
        //set the depth of the background so they do not overlap with any game objects
        this.sky.depth = -10;
        this.farBackground.depth = -9;
        this.background.depth = -8;
        this.trees.depth = -7;
        //Cue the snow and blizzard
        this.particles = this.add.particles('snowflakes');
        this.blizzardParticles = this.add.particles('snowflakes');
        this.blizzardParticles.depth = 3;
        this.setupSnow();
        this.blizzard = new blizzard_1.Blizzard(this);
        //Create the sled
        this.sledContainer = new sledContainer_1.SledContainer(this);
        //Set up a reccuring event to add a new house and chimney every 4 seconds        
        this.addNewHouse();
        let timedEvent = this.time.addEvent({ delay: 4000, callback: this.addNewHouse, callbackScope: this, loop: true });
        //Set our countdown timer
        this.secondsLeft = 30;
        this.countdownText = this.add.text(1040, 72, '30', { fontFamily: 'Script', fontSize: '40pt', fill: '#db3b31' });
        this.countdownText.setPadding(0, 0, 10, 0); // txt.setPadding(left, top, right, bottom);
        this.countdownText.setOrigin(0.5, 1);
        this.countdownText.depth = 1;
        let countdown = this.time.addEvent({ delay: 1000, callback: this.countdownSeconds, callbackScope: this, loop: true });
        //Set our score text
        this.scoreName = this.add.text(800, 25, String('Delivered'), { fontFamily: 'Sans', fontSize: '15pt', fill: '#b3dde3' });
        this.scoreText = this.add.text(865, 65, String(this.score), { fontFamily: 'Script', fontSize: '20pt', boundsAlignH: "center", boundsAlignV: "middle", fill: 'white' });
        this.scoreText.setPadding(0, 5, 10, 0);
        this.scoreText.setOrigin(0.5);
        this.scoreText.depth = 1;
        //Set our present progress bar
        this.presentBar = new presentBar_1.PresentBar(this);
        //Set up listener for clicks
        this.fireButton = new fireButton_1.fireButton(this, this.sledContainer);
        this.keySpace = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        this.pauseButton = new pause_1.pauseButton(this);
        this.muteButton = new muteButton_1.muteButton(this);
        //Destroy game objects that have left the screen
        this.destroyerOfAllThings = new destroyer_1.Destroyer(this);
        this.chimneyOverlap = new destroyer_1.ChimneyOverlap(this);
        //obstacle randomiser
        this.obstacleRandomizer = new obstacleRandomizer_1.ObstacleRandomizer(this);
        this.graphics = this.add.graphics();
    }
    update() {
        // Update the tile sprites for a continuous parallax background
        this.sky.tilePositionX += .15;
        this.farBackground.tilePositionX += .35;
        this.background.tilePositionX += .65;
        this.trees.tilePositionX += 2.4;
        //Add listener for space key to hit fire button
        if (this.keySpace.isDown) {
            if (this.isUp) {
                this.sledContainer.firePresent();
                this.fireButton.anims.play('fireButtonAnim');
                this.isUp = false;
            }
        }
        //this is to prevent multiple presents from firing until you have lifeted up on the spacebar
        if (this.keySpace.isUp) {
            this.isUp = true;
        }
        //Add the path the drone will follow
        this.graphics.clear();
        this.graphics.lineStyle(2, 0xffffff, 1);
        if (this.path != null) {
            //this.path.draw(this.graphics);
            if (this.drone != null) {
                if (this.drone.followPath) {
                    let t = this.drone.t;
                    let vec = this.drone.getData('vector');
                    //  The vector is updated in-place
                    this.path.getPoint(t, vec);
                    this.drone.setPosition(vec.x, vec.y);
                    this.drone.setDepth(this.drone.y);
                }
            }
        }
    }
    //Funciton to get the scenes chimney dimmensions
    getChimneyDimensions() {
        return this.chimneyDimensions;
    }
    ;
    //Function to add a roof to the scenes group of roofs
    addRoofToGroup(roof) {
        this.roofs.add(roof);
    }
    //Function to add a house to the scenes group of houses
    addHouseToGroup(house) {
        this.houses.add(house);
    }
    //Function to add a chimney to the scenes group of chimneys
    addChimneyToGroup(chimney) {
        this.chimneys.add(chimney);
    }
    //Function to add a present to the scenes group of presents
    addPresentToGroup(present) {
        this.presents.add(present);
    }
    //Function to add a chimney to the scenes group of chimneys
    addChimneyZoneToGroup(chimneyZone) {
        this.chimneyHitZones.add(chimneyZone);
    }
    //TODO move this to a new class 
    setupSnow() {
        //Set the snow depth to be at the front of the game
        this.particles.setDepth(3);
        //Add two particle emitters to the game
        this.particles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            //they will be positoned above and to the left of the window, 
            x: 1300,
            y: -300,
            //last for ten seconds
            lifespan: 10000,
            //how fast will they move
            speed: 200,
            //how big are they when the begin, and when they end (will make the snowflakes smaller)
            scale: { start: .3, end: .2 },
            blendMode: 'ADD',
            //will have them move across the screen to give the game a sense of motion
            gravityX: -50
        });
        this.particles.createEmitter({
            frame: [0, 1, 2, 3, 4, 5],
            x: 1800,
            y: -300,
            lifespan: 10000,
            speed: 200,
            scale: { start: .3, end: .2 },
            blendMode: 'ADD',
            gravityX: -50
        });
    }
    //Function to detect when a game object collides with the world edge.
    collideWithWorldEdge(body) {
        //If the object is a present
        if (body.gameObject instanceof present_1.Present) {
            this.destroyPresentSound.play('', {
                volume: this.gameVolume,
            });
            var present = body.gameObject;
            +present.anims.play('presentAnim', true);
            //add audio
            this.fireButton.anims.playReverse('fireButtonResetAnim', true);
        }
        //If the object is a drone destroy the drone then set up a timed event to play the destroyed drone sound effect
        else if (body.gameObject instanceof drone_1.Drone) {
            var drone = body.gameObject;
            drone.destroy();
            let timedEvent = this.time.addEvent({ delay: 500, callback: this.playDroneCollideWithWorld, args: [drone], callbackScope: this });
        }
        else {
            console.log(body.gameObject);
        }
    }
    //Function to play drone crashing sound effects and destroy the drone
    playDroneCollideWithWorld(drone) {
        if (this.secondsLeft > 0) {
            drone.dronePresentCollison.stop();
            this.destroyDroneSound.play('', {
                volume: this.gameVolume - .6,
            });
            delete this.drone;
            delete this.path;
        }
    }
    //Add a new House to the scene
    addNewHouse() {
        let house = new house_1.House(this, this.houseVelocity);
    }
    //Add wind to the scene
    addWind() {
        let wind = new wind_1.Wind(this);
    }
    //Add fog to the scene
    addFog() {
        let fog = new fog_1.Fog(this);
    }
    //Add a turtle dove bird obstacle which will travel accross the screen
    addTwoTurtleDoves(addSecondDove, playSound) {
        let dove1 = new doves_1.Doves(this, playSound);
        if (addSecondDove) {
            let delay = Math.floor(Math.random() * 400) + 400;
            let timedEvent = this.time.addEvent({ delay: delay, callback: this.addTwoTurtleDoves, args: [false, false], callbackScope: this });
        }
    }
    //Add wind to the scene
    addDrone() {
        let drone = new drone_1.Drone(this);
    }
    //this adds 
    addCountdownTimerTween() {
        let text = this.countdownText;
        let countdownTimerTween = this.tweens.addCounter({
            from: 30,
            to: 0,
            duration: 3000,
            onUpdate: function () {
                if (Math.floor(countdownTimerTween.getValue()) % 2 == 0) {
                    text.setFill('#db3b31');
                }
                else {
                    text.setFill('#FFFFFF');
                }
            }
        });
        this.countdownTimerTween = countdownTimerTween;
    }
    /**
     * an event listener thet is called every second and will update the timer end the game if there is no time left
     */
    countdownSeconds() {
        //adjust the time 
        this.secondsLeft--;
        this.countdownText.setText(String(this.secondsLeft));
        if (this.secondsLeft == 3) {
            this.addCountdownTimerTween();
            this.threeSecondsLeftSound.play('', {
                volume: .5
            });
        }
        if (this.secondsLeft == 2) {
            this.twoSecondsLeftSound.play('', {
                volume: .5
            });
        }
        if (this.secondsLeft == 1) {
            this.oneSecondLeftSound.play('', {
                volume: .5
            });
        }
        //if no time is left end the game
        if (this.secondsLeft <= 0) {
            this.scene.pause();
            this.sound.stopAll();
            this.data.set('score', this.score);
            // end the game by switching to game over scene
            this.time.removeAllEvents();
            this.resetGameVariables();
            this.scene.start("GameOverScene");
        }
    }
    //when the game ends reset the main scene variables 
    resetGameVariables() {
        //this.backgroundMusic.stop();
        this.registry.get('socket').emit('completeGame');
        this.score = 0;
        this.scoreText.setText("0");
        this.canThrow = 1;
        this.houseVelocity = -200;
        this.presentBarProgress = 0;
        this.presentBarScore = 1;
        delete this.drone;
        delete this.path;
        delete this.fog;
    }
}
exports.MainScene = MainScene;
