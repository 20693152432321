"use strict";
/**
 * @author       Daniel Knoben, Scott Dunham, Leah Ruisenor, Digitsensitive
 * @copyright    2018 Digitsensitive
 * @license      Digitsensitive
 */
Object.defineProperty(exports, "__esModule", { value: true });
class PauseScene extends Phaser.Scene {
    constructor() {
        super({
            key: "PauseScene"
        });
    }
    preload() {
        //Images
        this.load.image('mountains', './assets/game/mountains.png');
        this.load.image('deer', './assets/game/deer.png');
        this.load.image('intro', './assets/game/intro2.png');
        //Sprites
        this.load.spritesheet('resumeButton', './assets/game/play.png', { frameWidth: 96, frameHeight: 96 });
        this.load.spritesheet('startOver', './assets/game/restart.png', { frameWidth: 96, frameHeight: 96 });
    }
    create() {
        this.background = this.add.image(550, 390, 'mountains');
        this.deer = this.add.image(950, 310, 'deer');
        this.introBox = this.add.image(400, 300, 'intro');
        this.createStartPauseText();
        this.btnResume = this.add.sprite(320, 460, 'resumeButton').setInteractive();
        this.btnRestart = this.add.sprite(470, 460, 'startOver').setInteractive();
        this.btnResume.on('pointerdown', function (event) {
            this.scene.setVisible(true, "MainScene");
            this.scene.resume("MainScene");
            let MainScene = this.scene.get("MainScene");
            MainScene.sound.resumeAll();
            this.scene.stop("PauseScene");
        }, this);
        this.anims.create({
            key: 'startOverAnim',
            frames: this.anims.generateFrameNumbers('startOver', { start: 0, end: 1 }),
            frameRate: 12,
            yoyo: true,
        });
        this.btnRestart.on('pointerdown', function (event) {
            let MainScene = this.scene.get("MainScene");
            MainScene.resetGameVariables();
            MainScene.sound.stopAll();
            this.btnRestart.play('startOverAnim');
            this.registry.get('socket').emit('startGame');
            this.scene.start("MainScene");
        }, this);
    }
    update() {
    }
    createStartPauseText() {
        let thePauseTextLine1Bl = this.add.text(270, 160, "Those Presents Aren't going", { fontFamily: 'Sans', fontSize: '11pt', fill: '#0c5266' });
        let thePauseTextLine2Bl = this.add.text(295, 185, "to deliver themselves", { fontFamily: 'Sans', fontSize: '11pt', fill: '#0c5266' });
        let thePauseTextLine3R = this.add.text(300, 212, 'Top scores get gifts!', { fontFamily: 'Sans', fontSize: '11pt', fill: 'red' });
    }
}
exports.PauseScene = PauseScene;
