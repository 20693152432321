"use strict";
/**
 * @author       Digitsensitive <digit.sensitivee@gmail.com>
 * @copyright    2018 Digitsensitive
 * @license      Digitsensitive
 */
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path="../phaser.d.ts"/>
require("../less/styles.less");
require("phaser");
const startScene_1 = require("./scenes/startScene");
const mainScene_1 = require("./scenes/mainScene");
const gameOverScene_1 = require("./scenes/gameOverScene");
const pauseScene_1 = require("./scenes/pauseScene");
const socketIo = require("socket.io-client");
const screenfull = require("screenfull");
// main game configuration
const config = {
    type: Phaser.AUTO,
    width: 1100,
    height: 600,
    backgroundColor: '#0a5c71',
    physics: {
        default: 'arcade',
        arcade: {
            debug: false,
            gravity: { y: 0 }
        }
    },
    scene: [startScene_1.StartScene, mainScene_1.MainScene, gameOverScene_1.GameOverScene, pauseScene_1.PauseScene],
    input: {
        keyboard: true
    },
    parent: "game-container",
};
// game class
class Game extends Phaser.Game {
    constructor(config) {
        super(config);
        this.resizeGame();
    }
    resizeGame() {
        let canvas = this.canvas, width = window.innerWidth, height = window.innerHeight;
        let wratio = width / height, ratio = canvas.width / canvas.height;
        this.registry.set('canvasWidth', canvas.width);
        this.registry.set('canvasHeight', canvas.height);
        if (width < 1100 || height < 600) {
            if (wratio < ratio) {
                canvas.style.width = width + "px";
                canvas.style.height = (width / ratio) + "px";
            }
            else {
                canvas.style.width = (height * ratio) + "px";
                canvas.style.height = height + "px";
            }
        }
    }
}
exports.Game = Game;
// when the page is loaded, create our game instance
window.onload = () => {
    let game = new Game(config);
    game.registry.set('socket', socketIo());
    let canvas = game.canvas;
    let fullscreen = game.device.fullscreen;
    if (!fullscreen.available) {
        document.getElementById("overlay").style.display = "none";
    }
    document.getElementById("fullscreen").onclick = function () {
        if (!fullscreen.available) {
            if (screenfull !== false && screenfull.enabled) {
                screenfull.request(canvas.parentElement);
            }
        }
        else {
            canvas.parentElement[fullscreen.request]();
        }
    };
    //Resize the game on orientation change
    window.addEventListener("orientationchange", function () {
        window.setTimeout(function () {
            game.resizeGame();
        }, 200);
    }, false);
};
