"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Chimney extends Phaser.GameObjects.Sprite {
    constructor(scene, randomRoofTexture) {
        super(scene, Chimney.origin.x, Chimney.origin.y, Chimney.texture);
        this.chimneyDimensions = scene.getChimneyDimensions();
        this.chimneySizeMinWidth = this.chimneyDimensions.getWidth();
        this.chimneySizeRange = this.chimneyDimensions.getRange();
        this.roofTexture = randomRoofTexture;
        this.initChimneySprite(scene);
        this.currentScene.add.existing(this);
    }
    initChimneySprite(scene) {
        this.currentScene = scene;
        //Figure out if the Chimney will be on the left or right. 0 == right, 1 == left
        let sideOfHouse = Math.floor(Math.random() * 2);
        //Randomly Get a X Postion for the Chimney 
        let xPosition = Math.floor(Math.random() * 25) + 140;
        //If the Chimney will appear on the left, flip the image and make the x position negative
        if (sideOfHouse == 1) {
            xPosition = xPosition * -1;
            this.toggleFlipX();
        }
        //Set the X position
        this.setX(xPosition);
        //Calculate the Chimney Width, this is based off the game difficulty
        let chimneyWidth = Math.floor(Math.random() * this.chimneySizeRange) + this.chimneySizeMinWidth;
        //Use stove pipe image if the chimney is small enough
        if (chimneyWidth < 45) {
            this.setTexture('chimney1');
        }
        //if we are using the second roof texture, reposition the chimney
        if (this.roofTexture == 1 || this.roofTexture == 3) {
            this.setY(-70);
        }
        //Set the Chimney Height
        let chimneyHeight = Math.floor(Math.random() * 50) + 100;
        //Set the Scale
        this.setDisplaySize(chimneyWidth, chimneyHeight);
        //Give the chimney a name
        this.setName('chimney');
        this.currentScene.physics.world.enable(this);
        //The chimney should not move when objects (presents) collide with it
        this.body.immovable = true;
        this.body.moves = false;
        //We only care if a present hits the top of the chimney
        // this.body.checkCollision.left = false;
        // this.body.checkCollision.right = false;
        // this.body.checkCollision.down = false;
        //set the value of already hit to false
        this.alreadyhit = false;
        this.currentScene.addChimneyToGroup(this);
    }
    static hitChimney(present, chimneyZone) {
        //Add audio
        chimneyZone.scored = true;
        //Get the House Container this chimney is a part of
        let house = chimneyZone.parentContainer;
        //Get the roof of this house and animate turning the lights on
        let roof = house.getByName('roof');
        let chimney = house.getByName('chimney');
        //Light up the house
        if (chimney.roofTexture == 0) {
            roof.anims.play('roofAnim0', true);
        }
        else if (chimney.roofTexture == 1) {
            roof.anims.play('roofAnim1', true);
        }
        else if (chimney.roofTexture == 2) {
            roof.anims.play('roofAnim2', true);
        }
        else if (chimney.roofTexture == 3) {
            roof.anims.play('roofAnim3', true);
        }
        // Set the timer string and time to 5 seconds
        let scoreTimeString = "+5";
        let scoreTime = 5;
        //if the present passed through the wind or hit a bird they get a "bonus" of an additional 5 seconds for ten total 
        if (present.hitWind || present.hitBirds) {
            scoreTimeString = "+10";
            scoreTime = 10;
        }
        //Add text that will come out of the chimney to indicate how much time was added
        if (!chimney.alreadyhit) {
            let scoreText = chimney.currentScene.add.text(house.x + chimney.x - 10, house.y + chimney.y - 20, scoreTimeString, { fontFamily: 'Script', fontSize: '40pt', fill: '#ffec00' });
            scoreText.setPadding(0, 0, 10, 0); // txt.setPadding(left, top, right, bottom);
            scoreText.setOrigin(0.5, 1);
            scoreText.depth = -1;
            chimney.currentScene.tweens.add({
                targets: scoreText,
                duration: 500,
                scale: 100,
                y: house.y + chimney.y - 150,
                x: house.x + chimney.x - 100,
                ease: 'Linear',
                alpha: 0
            });
            //add the score time in seconds to the timer
            chimney.currentScene.secondsLeft = chimney.currentScene.secondsLeft + scoreTime;
            chimney.currentScene.countdownText.setText(String(chimney.currentScene.secondsLeft));
            if (chimney.currentScene.countdownTimerTween) {
                chimney.currentScene.countdownTimerTween.stop();
                chimney.currentScene.countdownText.setFill('#db3b31');
            }
            /**********************************
            lets update the present bar
            ***********************************/
            //Let's get the sprite frame we are currenlty using which is called the name, even though it's a number
            let frameName = chimney.currentScene.presentBar.frame.name;
            //update the frame to the next frame
            let frame = +frameName;
            //if we are not at the last frame lets shift to the next image in the sprite
            if (frame < 9) {
                frame++;
                chimney.currentScene.presentBar.setFrame(frame);
            }
            //update the presnt bar progress
            chimney.currentScene.presentBarProgress++;
            //if they have hit 3 chimney's in a row and the present bar is at 3, (and they don't already have the max present bar score of 4)
            if (chimney.currentScene.presentBarProgress == 3 && chimney.currentScene.presentBarScore < 4) {
                //allow the user to thorw one more present
                chimney.currentScene.canThrow++;
                //update the present bar score by 1 (this correlates to how many presnts can be thrown at once)
                chimney.currentScene.presentBarScore++;
                //reset the progress bar to zero (this correlates to how many chimneys in a row they have hit if they have hit three in a row, reset this to zero and update the score variable by one)
                chimney.currentScene.presentBarProgress = 0;
                let presentPowerUpMessage = "";
                if (chimney.currentScene.presentBarScore == 2) {
                    presentPowerUpMessage = "Double Shot";
                }
                else if (chimney.currentScene.presentBarScore == 3) {
                    presentPowerUpMessage = "Triple Shot";
                }
                else if (chimney.currentScene.presentBarScore == 4) {
                    presentPowerUpMessage = "Quad Shot";
                }
                let presentBarText = chimney.currentScene.add.text(550, 300, presentPowerUpMessage, { fontFamily: 'Script', fontSize: '40pt', fill: '#ffec00' });
                presentBarText.setPadding(0, 0, 10, 0); // txt.setPadding(left, top, right, bottom);
                presentBarText.setOrigin(0.5, 1);
                presentBarText.depth = -1;
                chimney.currentScene.tweens.add({
                    targets: presentBarText,
                    duration: 3000,
                    scale: 100,
                    ease: 'Linear',
                    alpha: 0
                });
                let hitChimneySound = chimneyZone.currentScene.sound.add('double-shot-earned');
                hitChimneySound.play('', {
                    volume: 1,
                });
            }
            else {
                let hitChimneySound = chimneyZone.currentScene.sound.add('gift-hits-chimney');
                hitChimneySound.play('', {
                    volume: 1,
                });
            }
        }
        //if the user gets another throw if the presnet is destroyed, add one to the number of presnets they can throw
        if (present.getThrowIfDestroyed) {
            chimney.currentScene.canThrow++;
        }
        //destroy the present
        present.destroy();
        //add one to their score
        chimney.currentScene.registry.get('socket').emit('hitChimney');
        chimney.currentScene.score++;
        chimney.currentScene.scoreText.setText(String(chimney.currentScene.score));
        chimney.currentScene.fireButton.anims.playReverse('fireButtonResetAnim', true);
        //Adjust the Chimney Dimensions
        chimney.currentScene.chimneyDimensions.chnageChimneyDimensionsByScore(chimney.currentScene.score);
        //TODO move this if else statment into a game options class
        //adjust the house velocity based on the score
        if (chimney.currentScene.score >= 3 && chimney.currentScene.score < 6) {
            chimney.currentScene.houseVelocity = -250;
            chimney.currentScene.obstacleRandomizer.timedEvent.delay = 5000; //set time distance between obstacles to 5sec
        }
        else if (chimney.currentScene.score >= 6 && chimney.currentScene.score < 10) {
            chimney.currentScene.houseVelocity = -300;
            chimney.currentScene.obstacleRandomizer.timedEvent.delay = 4000; //set the time distance between obstacles to 4sec
        }
        else if (chimney.currentScene.score >= 10 && chimney.currentScene.score < 15) {
            chimney.currentScene.houseVelocity = -350;
            chimney.currentScene.obstacleRandomizer.timedEvent.delay = 3000; //set the time distance between obstacles to 3sec 
        }
        else if (chimney.currentScene.score >= 15 && chimney.currentScene.score < 20) {
            chimney.currentScene.houseVelocity = -400;
            chimney.currentScene.obstacleRandomizer.timedEvent.delay = 2000; //set the time distance between obstacles to 2sec
        }
        //based on the new velocity adjust the velocity of each house in the game
        let arrHouses = chimney.currentScene.houses.getChildren();
        // Note: The Group class getChildren function return type is an array of GameObjects
        // As a result, we need to specify that we're dealing with our House class in this case.
        arrHouses.forEach((house) => {
            house.body.setVelocity(chimney.currentScene.houseVelocity, 0);
        });
        //indicate the chimney has already been hit so no more time is added if a second present also hits the chimney
        chimney.alreadyhit = true;
    }
}
Chimney.origin = { x: 150, y: -15 };
Chimney.texture = 'chimney0';
exports.Chimney = Chimney;
class chimneyDimensions {
    constructor() {
        this.chimneySizeRange = 10;
        this.chimneySizeMinWidth = 90;
    }
    getRange() {
        return this.chimneySizeRange;
    }
    getWidth() {
        return this.chimneySizeMinWidth;
    }
    setChimneyDimensions(range, width) {
        this.chimneySizeRange = range;
        this.chimneySizeMinWidth = width;
    }
    chnageChimneyDimensionsByScore(score) {
        if (score >= 3 && score < 6) {
            //New Size Range is 75-100
            this.chimneySizeRange = 25; //up from 10
            this.chimneySizeMinWidth = 75; //down from 90
        }
        else if (score >= 6 && score < 10) {
            //New Size Range is 50-100
            this.chimneySizeRange = 50; //up from 25
            this.chimneySizeMinWidth = 50; //down from 75
        }
        else if (score >= 10 && score < 15) {
            //New Size Range is 25-75
            this.chimneySizeRange = 50; //same
            this.chimneySizeMinWidth = 35; //down from 50        
        }
        else if (score >= 15 && score < 20) {
            ///New Size Range is 25-50
            this.chimneySizeRange = 25; //down from 50
            this.chimneySizeMinWidth = 35; //down from 50
        }
    }
}
exports.chimneyDimensions = chimneyDimensions;
