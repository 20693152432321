"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Fog extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, Fog.origin.x, Fog.origin.y, Fog.texture);
        this.initFog(scene);
        this.currentScene.fog = this;
        this.scene.add.existing(this);
    }
    /**
     * add fog moving across the scene
     * @param scene
     */
    initFog(scene) {
        this.currentScene = scene;
        this.depth = 20;
        let timedEvent = this.currentScene.time.addEvent({ delay: 3500, callback: this.playfogSound, args: [], callbackScope: this });
        this.scene.physics.world.enable(this);
        //move it across the screen
        this.body.setVelocity(-400, 0);
        //add a callback that detects if fog collides with the zone of destruction
        this.currentScene.physics.add.overlap(this, this.currentScene.destroyerOfAllThings, Fog.destroyFog, null, null);
    }
    playfogSound() {
        let fogSound = this.currentScene.sound.add('fog');
        fogSound.play('', {
            volume: this.currentScene.gameVolume / 3,
        });
    }
    /**
     * If a fog collides with the zone of destruction off screen destroy it
     */
    static destroyFog(fog) {
        //console.log("wind destroyed");
        fog.currentScene.fog = null;
        fog.destroy();
    }
}
Fog.origin = { x: 3500, y: 300 };
Fog.texture = 'fog';
exports.Fog = Fog;
