"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Sled extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, Sled.origin.x, Sled.origin.y, Sled.texture);
        this.initSledSprite(scene);
        this.scene.add.existing(this);
    }
    initSledSprite(scene) {
        this.currentScene = scene;
        this.depth = 2;
        //Add Sled Animatoin
        //TODO possibly move this out to it's own class if we ever need to add more then one sled to the scene
        this.currentScene.anims.create({
            key: 'sledAnim',
            frames: this.currentScene.anims.generateFrameNumbers('sled', { start: 0, end: 35 }),
            frameRate: 17,
            repeat: -1
        });
        this.anims.play('sledAnim', true);
    }
}
Sled.origin = { x: 0, y: 0 };
Sled.texture = 'sled';
exports.Sled = Sled;
