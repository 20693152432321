"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class pauseButton extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, pauseButton.origin.x, pauseButton.origin.y, pauseButton.texture);
        this.initPauseButtonSprite(scene);
        this.scene.add.existing(this);
    }
    initPauseButtonSprite(scene) {
        this.currentScene = scene;
        this.depth = 2;
        //this.setDisplaySize(50, 50);
        this.setInteractive();
        this.currentScene.anims.create({
            key: 'pauseButtonAnim',
            frames: this.currentScene.anims.generateFrameNumbers('pauseButton', { start: 0, end: 1 }),
            frameRate: 12,
            yoyo: true,
        });
        this.on('pointerdown', function (event) {
            this.anims.play('pauseButtonAnim');
        }, this);
        this.on('animationcomplete', this.pauseButtonAnimComplete, this);
    }
    pauseButtonAnimComplete(animation, frame) {
        this.currentScene.scene.pause();
        this.currentScene.scene.setVisible(false);
        this.currentScene.sound.pauseAll();
        this.currentScene.scene.launch("PauseScene");
    }
}
pauseButton.origin = { x: 100, y: 400 };
pauseButton.texture = 'pauseButton';
exports.pauseButton = pauseButton;
