"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Doves extends Phaser.GameObjects.Sprite {
    constructor(scene, playSound) {
        super(scene, Doves.origin.x, Doves.origin.y, Doves.texture);
        this.ease = ['Quad.easeInOut', 'Cubic.easeInOut', 'Sine.easeInOut'];
        this.initDovesSprite(scene, playSound);
        this.scene.add.existing(this);
    }
    initDovesSprite(scene, playSound) {
        this.currentScene = scene;
        this.hit = false;
        this.currentScene.physics.world.enable(this);
        this.body.setVelocity(-300, 0);
        this.body.setSize(55, 55);
        //add sound
        if (playSound) {
            let flightSound = this.currentScene.sound.add('turtle-dove-flight');
            flightSound.play('', {
                volume: this.currentScene.gameVolume,
            });
        }
        //Set up some random easing for the birds flight path
        let easeRandom = Math.floor(Math.random() * this.ease.length);
        let duartion = Math.floor(Math.random() * 350) + 1000;
        let y = Math.floor(Math.random() * 40) + 290;
        //Add a tween to have the bird move up and down
        this.currentScene.tweens.add({
            targets: this,
            y: y,
            duration: duartion,
            ease: this.ease[easeRandom],
            yoyo: true,
            repeat: -1
        });
        this.play('dovesAnim');
        this.on('animationcomplete', this.dovesAnimationComplete, this);
        //add a callback that detects if a present collides with the doves
        this.currentScene.physics.add.collider(this, this.currentScene.presents, Doves.hitDoves, null, null);
        //add a callback that detects if dove collides with the zone of destruction
        this.currentScene.physics.add.overlap(this, this.currentScene.destroyerOfAllThings, Doves.destroyDoves, null, null);
    }
    dovesAnimationComplete() {
        //console.log(this); 
        if (this.anims.currentAnim.key == "doveCollisonAnim") {
            this.destroy();
        }
    }
    /**
     * If a present hits the dove
     */
    static hitDoves(doves, present) {
        if (doves.hit === false) {
            doves.hit = true;
            //add sound      
            doves.currentScene.hitBirdSound.play('', {
                volume: doves.currentScene.gameVolume,
            });
            //spin the doves
            doves.anims.remove('dovesAnim');
            doves.play('doveCollisonAnim');
            present.hitBirds = true;
        }
    }
    /**
     * If a dove collides with the zone of destruction off screen destroy it
     */
    static destroyDoves(doves) {
        //console.log("dove destroyed");
        doves.destroy();
    }
}
Doves.origin = { x: 1200, y: 270 };
Doves.texture = 'doves';
exports.Doves = Doves;
class DoveAnimation {
    constructor(scene) {
        //private animation: Phaser.Animations.Animation;
        this.animationKey = 'dovesAnim';
        this.currentScene = scene;
    }
    addDovesAnimation() {
        //Add the house animation to light up the house
        this.currentScene.anims.create({
            key: this.animationKey,
            frames: this.currentScene.anims.generateFrameNumbers(DoveAnimation.texture, { start: 0, end: 3 }),
            frameRate: 24,
            repeat: -1
        });
    }
    addDoveCollisonAnimation() {
        //Add the house animation to light up the house
        this.currentScene.anims.create({
            key: "doveCollisonAnim",
            frames: this.currentScene.anims.generateFrameNumbers(DoveAnimation.texture, { start: 4, end: 7 }),
            frameRate: 20,
            repeat: 0
        });
    }
}
DoveAnimation.texture = 'doves';
exports.DoveAnimation = DoveAnimation;
