"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class muteButton extends Phaser.GameObjects.Sprite {
    constructor(scene) {
        super(scene, muteButton.origin.x, muteButton.origin.y, muteButton.texture);
        this.muted = false;
        this.initMuteButtonSprite(scene);
        this.scene.add.existing(this);
    }
    initMuteButtonSprite(scene) {
        this.currentScene = scene;
        this.depth = 2;
        //this.setDisplaySize(50, 50);
        this.setInteractive();
        this.currentScene.anims.create({
            key: 'muteButtonAnim',
            frames: this.currentScene.anims.generateFrameNumbers('muteButton', { start: 0, end: 2 }),
            frameRate: 12
        });
        this.on('pointerdown', function (event) {
            if (this.muted) {
                this.muted = false;
                this.anims.playReverse("muteButtonAnim");
            }
            else {
                this.anims.play("muteButtonAnim");
                this.muted = true;
            }
            this.currentScene.sound.setMute(this.muted);
        }, this);
    }
}
muteButton.origin = { x: 100, y: 330 };
muteButton.texture = 'muteButton';
exports.muteButton = muteButton;
