"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class fireButton extends Phaser.GameObjects.Sprite {
    constructor(scene, sledContainer) {
        super(scene, fireButton.origin.x, fireButton.origin.y, fireButton.texture);
        this.initFireButtonSprite(scene, sledContainer);
        this.scene.add.existing(this);
    }
    initFireButtonSprite(scene, sledContainer) {
        this.currentScene = scene;
        this.depth = 2;
        this.setInteractive();
        //TODO possibly move this out to it's own class if we ever need to add more then one sled to the scene
        this.currentScene.anims.create({
            key: 'fireButtonAnim',
            frames: this.currentScene.anims.generateFrameNumbers('fireButton', { start: 0, end: 1 }),
            frameRate: 12,
        });
        this.on('pointerdown', function (event) {
            this.anims.play('fireButtonAnim');
            sledContainer.firePresent();
        }, this);
    }
}
fireButton.origin = { x: 100, y: 500 };
fireButton.texture = 'fireButton';
exports.fireButton = fireButton;
