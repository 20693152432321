"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class CannonStars extends Phaser.GameObjects.Particles.ParticleEmitterManager {
    constructor(scene, x, y) {
        super(scene, CannonStars.texture);
        this.initStarsSprite(scene, x, y);
        this.scene.add.existing(this);
    }
    initStarsSprite(scene, x, y) {
        this.currentScene = scene;
        let xPoint = x - 40;
        let yPoint = y + 70;
        //Set the snow depth to be at the front of the game
        this.setDepth(2);
        let particleConfig = {
            frames: [0],
            x: xPoint,
            y: yPoint,
            //last for one seconds
            lifespan: 1000,
            //how fast will they move
            speed: { min: 50, max: 200 },
            //how big are they when the begin, and when they end (will make the snowflakes smaller)
            scale: { start: 3, end: .1 },
            //not on by default
            on: false,
            //only emit from specified angle
            radial: true,
            angle: { min: -50, max: 50 }
        };
        //Add particel emitter to the game
        let particleEmitter = new Phaser.GameObjects.Particles.ParticleEmitter(this, particleConfig);
        this.addEmitter(particleEmitter);
    }
}
CannonStars.origin = { x: 0, y: 0 };
CannonStars.texture = 'cannonStars';
exports.CannonStars = CannonStars;
