"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ChimneyHitZone extends Phaser.GameObjects.Zone {
    constructor(scene, x, y, width) {
        super(scene, x, y, width, 10);
        this.scored = false;
        this.hitEdgeOrWorld = false;
        this.initHitZone(scene);
        this.scene.add.existing(this);
    }
    initHitZone(scene) {
        this.currentScene = scene;
        this.currentScene.physics.world.enable(this);
        this.body.immovable = true;
        //this.body.moves = false;
        this.currentScene.addChimneyZoneToGroup(this);
    }
}
exports.ChimneyHitZone = ChimneyHitZone;
class PresentAnimation {
    constructor(scene) {
        //private animation: Phaser.Animations.Animation;
        this.animationKey = 'presentAnim';
        this.currentScene = scene;
    }
    addPresentAnimation() {
        //when present is destroyed plays animation
        this.currentScene.anims.create({
            key: this.animationKey,
            frames: this.currentScene.anims.generateFrameNumbers(PresentAnimation.texture, { start: 0, end: 7 }),
            frameRate: 24,
            repeat: 0
        });
    }
}
PresentAnimation.texture = 'present';
exports.PresentAnimation = PresentAnimation;
